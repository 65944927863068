import { render, staticRenderFns } from "./waitExtract.vue?vue&type=template&id=775ca295&scoped=true&"
import script from "./waitExtract.vue?vue&type=script&lang=js&"
export * from "./waitExtract.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "775ca295",
  null
  
)

export default component.exports