<template>
    <div style="background:#F2F2F2;">
        <div v-if="isModule === false" style="background:#fff;max-width:1920px;margin:0 auto;">
            <div style="height: 80px;">
                <div class="d-flex h-100" style="border-bottom: 1px solid #ccc;">
                    <div class="flex-1 h-100 d-flex a-center p-l-lg text-left">
                        <img :src="anJunImg" style="height: 60px" alt="安骏跨境物流">
                    </div>
                    <div class="d-flex a-center text-right p-r-lg">
                      <div class="selectLg">
                        <span class="lgCon">{{ $i18n.locale === 'zh-CN' ? $t('language.CN') : ($i18n.locale === 'en-US' ? $t('language.EN') : $t('language.PT')) }}</span>
                        <div class="selectCon">
                          <div style="margin-bottom: 10px;">
                            <span
                                :style="{'color': $i18n.locale === 'zh-CN' ? '#009B4C' : '#666'}"
                                @click="changeLanguage('zh-CN')"
                                class="m-r-md cursor-pointer font-sm">{{ $t('language.CN') }}</span>
                          </div>
                          <div style="margin-bottom: 10px;">
                            <span
                                :style="{'color': $i18n.locale === 'en-US' ? '#009B4C' : '#666'}"
                                @click="changeLanguage('en-US')"
                                class="m-r-md cursor-pointer font-sm">{{ $t('language.EN') }}</span>
                          </div>
                          <div>
                            <span
                                :style="{'color': $i18n.locale === 'pu-PT' ? '#009B4C' : '#666'}"
                                @click="changeLanguage('pu-PT')"
                                class="m-r-md cursor-pointer font-sm">{{ $t('language.PT') }}</span>
                          </div>
                        </div>
                      </div>
<!--                        <span v-if="hrefStr === -1 && $i18n.locale === 'en-US'"-->
<!--                              style="color: #009B4C;"-->
<!--                              @click="changeLanguage()"-->
<!--                              class="m-r-md cursor-pointer font-sm">{{ $t('language.CN') }}</span>-->
<!--                        <span v-if="hrefStr === -1 && $i18n.locale === 'zh-CN'"-->
<!--                              style="color: #009B4C;"-->
<!--                              @click="changeLanguage()"-->
<!--                              class="m-r-md cursor-pointer font-sm">{{ $t('language.EN') }}</span>-->
                        <span class="headerBtn cursor-pointer" @click="toNewWindow(1)">{{ $t('language.returnToHome') }}</span>
                        <span class="headerBtn m-l-md cursor-pointer" @click="toNewWindow(2)">{{ $t('language.register') }}</span>
                    </div>
                </div>
            </div>
            <div class="d-flex p-md" style="background:#F2F2F2;height: calc(100vh - 160px);overflow-y: auto;">
                <div class="flex-1 p-r-md">
                    <div class="text-left container-out">
                        <div style="display: inline-block;height: 36px;width: calc(100% - 380px);">
                            <div v-if="loopDivWidth < divWidth" class="left-con iconfont icon-arrow-left-bold" @click="loopLeft()"></div>
                            <div ref="loopCon" class="loopCon">
                              <ul :style="{width: divWidth + 'px', left: divLeft + 'px', top: divTop + 'px'}" class="item-con">
                                <li ref="li1" class="clear-float cursor-pointer d-inline-block d-flex a-center"
                                    :class="activeItem === 100 ? 'borderBtm': ''"
                                    @click="changeStatus(100)">
                                  <span class="float-l" style="height:20px;"></span>
                                  <span class="m-l-xs float-l">{{ $t('language.allTracks') }}({{ orderNumber.orderAll.length }})</span>
                                </li>
                                <li ref="li2" class="clear-float cursor-pointer d-inline-block"
                                    :class="activeItem === 0 ? 'borderBtm': ''"
                                    @click="changeStatus(0)">
                                  <img :src="iconImg.cbd" class="float-l" style="width:20px;height:20px;">
                                  <span class="m-l-xs float-l" style="color:#707070;">{{ $t('language.queryNotFound') }}({{ orderNumber.notFound.length }})</span>
                                </li>
                                <li ref="li3" class="clear-float cursor-pointer d-inline-block"
                                    :class="activeItem === 5 ? 'borderBtm': ''"
                                    @click="changeStatus(5)">
                                  <img :src="iconImg.yuz" class="float-l" style="width:20px;height:20px;">
                                  <span class="float-l m-l-xs" style="color:#1296DB;">{{ $t('language.duringTransportation') }}({{ orderNumber.inTransit.length }})</span>
                                </li>
                                <li ref="li4" class="clear-float cursor-pointer d-inline-block"
                                    :class="activeItem === 1 ? 'borderBtm': ''"
                                    @click="changeStatus(1)">
                                  <img :src="iconImg.ddq" class="float-l" style="width:20px;height:20px;">
                                  <span class="float-l m-l-xs" style="color:#17267D;">{{ $t('language.download') }}({{ orderNumber.arriveCollect.length }})</span>
                                </li>
                                <li ref="li5" class="clear-float cursor-pointer d-inline-block"
                                    :class="activeItem === 2 ? 'borderBtm': ''"
                                    @click="changeStatus(2)">
                                  <img :src="iconImg.tsb" class="float-l" style="width:20px;height:20px;">
                                  <span class="float-l m-l-xs" style="color:#FF0033;">{{ $t('language.deliveryFailed') }}({{ orderNumber.deliveryFailed.length }})</span>
                                </li>
                                <li ref="li6" class="clear-float cursor-pointer d-inline-block"
                                    :class="activeItem === 3 ? 'borderBtm': ''"
                                    @click="changeStatus(3)">
                                  <img :src="iconImg.yqs" class="float-l" style="width:20px;height:20px;">
                                  <span class="float-l m-l-xs" style="color:#05C465;">{{ $t('language.signedIn') }}({{ orderNumber.successfulSignIn.length }})</span>
                                </li>
                              </ul>
                            </div>
                            <div v-if="loopDivWidth < divWidth" class="right-con iconfont icon-arrow-right-bold" @click="loopRight()"></div>
                        </div>
                        <div class="text-right m-r-sm qq-con">
                            {{ $t('language.customerService') }}QQ：4009996128
                        </div>
                    </div>
                    <div class="p-h-md">
                        <div v-if="activeItem === 100">
                            <all-trajectory :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                        <div v-if="activeItem === 0">
                            <can-not-find :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                        <div v-if="activeItem === 1">
                            <wait-extract :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                        <div v-if="activeItem === 2">
                            <delivery-failed :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                        <div v-if="activeItem === 3">
                            <signed-in :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                        <div v-if="activeItem === 5">
                            <in-transit :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                    </div>
                </div>
                <div style="width: 300px;">
                    <textarea
                        rows="20"
                        v-model="orderContainer"
                        class="w-100"
                        :placeholder="$t('language.placeholder')"></textarea>
                    <div style="color: #f00;text-align: left;">{{ $t('language.beCareful') }}：{{ $t('language.upToQueries') }}</div>
                    <div class="d-flex a-center j-center font-white font-bold m-t-sm cursor-pointer"
                         style="height: 40px;background:#009B4C;border-radius: 5px;font-size: 18px;"
                         @click="queryOrderClick()">
                        <span :style="{'letter-spacing': $i18n.locale === 'zh-CN' ? '10px' : 0}">{{ $t('language.submit') }}</span>
                    </div>
                    <div class="m-t-sm">
                        <img :src="kuaJin" class="w-100">
                    </div>
                </div>
            </div>
            <div style="height: 80px;background:#009B4C;"
                 class="font-sm font-white d-flex flex-column a-center j-center">
                <div>@ Copyright 2017 szanjuntrack.Com Rights Reserved</div>
              <div>
                <span>{{ $t('language.anjun') }}</span>
                <a class="m-l-sm" target="_blank" href="https://beian.miit.gov.cn">粤ICP备19143526号-3</a>
<!--                <a class="m-l-sm" target="_blank" href="https://www.beian.gov.cn">京网备44030702004669</a>-->
              </div>
            </div>
        </div>
        <div v-else>
          <div style="height: 80px;">
            <div class="d-flex h-100" style="border-bottom: 1px solid #ccc;">
              <div class="flex-1 h-100 d-flex a-center p-l-sm text-left">
                <img :src="anJunImg" style="height: 60px" alt="安骏跨境物流">
              </div>
              <div class="d-flex a-center text-right">
                <div class="selectLg">
                  <span class="lgCon">{{ $i18n.locale === 'zh-CN' ? $t('language.CN') : ($i18n.locale === 'en-US' ? $t('language.EN') : $t('language.PT')) }}</span>
                  <div class="selectCon">
                    <div style="margin-bottom: 10px;">
                            <div
                                :style="{'color': $i18n.locale === 'zh-CN' ? '#009B4C' : '#666'}"
                                @click="changeLanguage('zh-CN')"
                                class="m-r-md cursor-pointer font-sm">{{ $t('language.CN') }}</div>
                    </div>
                    <div style="margin-bottom: 10px;">
                            <div
                                :style="{'color': $i18n.locale === 'en-US' ? '#009B4C' : '#666'}"
                                @click="changeLanguage('en-US')"
                                class="m-r-md cursor-pointer font-sm">{{ $t('language.EN') }}</div>
                    </div>
                    <div>
                            <div
                                :style="{'color': $i18n.locale === 'pu-PT' ? '#009B4C' : '#666'}"
                                @click="changeLanguage('pu-PT')"
                                class="m-r-md cursor-pointer font-sm">{{ $t('language.PT') }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-sm" style="background:#F2F2F2;height: calc(100vh - 160px);overflow-y: auto;">
            <div class="p-h-0">
<!--              <div v-if="activeItem === 100">-->
                <all-trajectory :orderNumber="orderNumber" :iconImg="iconImg" />
<!--              </div>-->
<!--              <div v-if="activeItem === 0">-->
<!--                <can-not-find :orderNumber="orderNumber" :iconImg="iconImg" />-->
<!--              </div>-->
<!--              <div v-if="activeItem === 1">-->
<!--                <wait-extract :orderNumber="orderNumber" :iconImg="iconImg" />-->
<!--              </div>-->
<!--              <div v-if="activeItem === 2">-->
<!--                <delivery-failed :orderNumber="orderNumber" :iconImg="iconImg" />-->
<!--              </div>-->
<!--              <div v-if="activeItem === 3">-->
<!--                <signed-in :orderNumber="orderNumber" :iconImg="iconImg" />-->
<!--              </div>-->
<!--              <div v-if="activeItem === 5">-->
<!--                <in-transit :orderNumber="orderNumber" :iconImg="iconImg" />-->
<!--              </div>-->
            </div>
          </div>
          <div style="height: 80px;background:#009B4C;" class="font-sm font-white d-flex flex-column a-center j-center">
            <div>@ Copyright 2017 szanjuntrack.Com Rights Reserved</div>
            <div>
              <a class="m-l-sm" target="_blank" href="https://beian.miit.gov.cn">粤ICP备19143526号-3</a>
<!--              <a class="m-l-sm" target="_blank" href="https://www.beian.gov.cn">京网备44030702004669</a>-->
              <div>{{ $t('language.anjun') }}</div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    import anjun from '@/assets/image/trajectory/anjuntrack.png';
    import logo from '@/assets/image/trajectory/logo.png';
    import logoPu from '@/assets/image/trajectory/logo_pu.png';
    import kuajing from '@/assets/image/trajectory/u241.png';
    import cbd from '@/assets/image/trajectory/u122.png';
    import yqs from '@/assets/image/trajectory/u140.png';
    import yuz from '@/assets/image/trajectory/u188.png';
    import ddq from '@/assets/image/trajectory/u156.png';
    import tsb from '@/assets/image/trajectory/u172.png';
    import jt from '@/assets/image/trajectory/u120.png';
    import { getTrackQueryData } from '@/views/trajectory/trajectoryApi';
    import allTrajectory from '@/views/trajectory/com/allTrajectory';
    import canNotFind from '@/views/trajectory/com/canNotFind';
    import waitExtract from '@/views/trajectory/com/waitExtract';
    import deliveryFailed from '@/views/trajectory/com/deliveryFailed';
    import signedIn from '@/views/trajectory/com/signedIn';
    import inTransit from '@/views/trajectory/com/inTransit';
    export default {
        name: '',
        components: {
            allTrajectory,
            canNotFind,
            waitExtract,
            deliveryFailed,
            signedIn,
            inTransit
        },
        data() {
            return {
                anJunImg: anjun,
                kuaJin: kuajing,
                iconImg: {
                    cbd: cbd,
                    yuz: yuz,
                    yqs: yqs,
                    ddq: ddq,
                    tsb: tsb,
                    jt: jt
                },
                activeItem: 100,
                activeName: '',
                orderNumber: {
                    orderAll: [],
                    notFound: [],
                    inTransit: [],
                    arriveCollect: [],
                    deliveryFailed: [],
                    successfulSignIn: [],
                    itMayBeAbnormal: []
                },
                orderContainer: '',
                isClick: true,
                orderNumArr: [],
                hrefStr: '',
                isModule: false,
                divWidth: 100,
                divLeft: 0,
                divTop: 0,
                loopWidth: 0,
                loopDivWidth: 0
            }
        },
        created() {
            this.isModule = window.equipmentParam;
            this.hrefStr = window.location.href.indexOf('pu');
            if (this.hrefStr !== -1) {
              this.$i18n.locale = 'pu-PT';
              this.anJunImg = logoPu;
            }

            if (this.GetQueryString('t')) {
              this.orderContainer = this.GetQueryString('t').replace(/,/g,"\n");
              this.queryOrder();
            }
            if (this.isModule === false) {
              this.getDivWidth();
            }
        },
        methods: {
          // 宽度计算
          getDivWidth() {
              if (this.isModule === false) {
                this.$nextTick(() => {
                  // const a = this.$refs.li1.offsetWidth + 20;
                  // const b = this.$refs.li2.offsetWidth + 20;
                  // const c = this.$refs.li3.offsetWidth + 20;
                  // const d = this.$refs.li4.offsetWidth + 20;
                  // const e = this.$refs.li5.offsetWidth + 20;
                  // const f = this.$refs.li6.offsetWidth + 20;
                  if (this.$i18n.locale === 'zh-CN') {
                    this.divWidth = 700;
                  } else if (this.$i18n.locale === 'en-US') {
                    this.divWidth = 960;
                  } else {
                    this.divWidth = 1100;
                  }
                  // this.divWidth = a + b + c + d + e + f;
                  this.loopDivWidth = this.$refs.loopCon.offsetWidth;
                  this.loopWidth = this.divWidth - this.$refs.loopCon.offsetWidth;
                })
              }
          },
          // 左移
          loopLeft() {
            if (this.divLeft > -this.loopWidth) {
              this.divLeft = this.divLeft - 50;
            }
          },
          // 右移
          loopRight() {
            if (this.divLeft < 0) {
              this.divLeft = this.divLeft + 50;
            }
          },
          // 获取地址栏参数
          GetQueryString(name) {
            const reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
            const r = window.location.search.substr(1).match(reg);
            if(r!=null)return unescape(r[2]); return null;
          },
          // 点击查询传参地址栏刷新页面查询轨迹
          queryOrderClick() {
            this.getQueryNumber();
            const param = this.orderNumArr.join(',');
            if (param) {
              this.$router.replace({name:'trackQuery', query: {t:param}});//params:{orderNum: this.valueContainer}});
              this.orderContainer = this.GetQueryString('t').replace(/,/g,"\n");
              this.queryOrder();
            } else {
              this.$router.replace({name:'trackQuery'});
              this.orderNumber = {
                orderAll: [],
                notFound: [],
                inTransit: [],
                arriveCollect: [],
                deliveryFailed: [],
                successfulSignIn: [],
                itMayBeAbnormal: []
              }
            }
          },
//            状态切换
            changeStatus(type) {
                this.activeItem = type;
            },
//            获取查询订单数量
            getQueryNumber() {
                const orderNum = this.orderContainer.split(/[(\r\n)\r\n]+/);
                this.orderNumArr = [];
                for (let j = 0; j < orderNum.length; j++) {
                    if (orderNum[j] !== '') {
                        const orderNumber = orderNum[j].trim();
                        if (this.orderNumArr.indexOf(orderNumber) === -1) {
                            this.orderNumArr.push(orderNumber.trim());
                        }
                    }
                }
            },
//            获取轨迹
            queryOrder() {
                if (this.isClick === true) {
                    this.isClick = false;
                    this.orderNumber.orderAll = [];
                    this.orderNumber.arriveCollect = [];
                    this.orderNumber.deliveryFailed = [];
                    this.orderNumber.inTransit = [];
                    this.orderNumber.notFound = [];
                    this.orderNumber.itMayBeAbnormal = [];
                    this.orderNumber.successfulSignIn = [];
                    this.getQueryNumber();

                    const dataArr = [];
                    this.orderNumArr.map((item, index) => {
                        if (index < 50) {
                            dataArr.push(item);
                        }
                    });
//                    将数组内元素分为多个小数组
                    const group = (array, subGroupLength) => {
                        let index = 0;
                        let newArray = [];
                        while(index < array.length) {
                            newArray.push(array.slice(index, index += subGroupLength));
                        }
                        return newArray;
                    };

                    const groupArr = group(dataArr, 10);

                    const getArr = (groupArr) => {
                        let map = [];
                        let index = 0;
                        if (index < groupArr.length) {
                            for (let j = 0; j < groupArr[index].length; j++) {
                                map.push(new Promise((resolve) => {
                                    let orderNo = groupArr[index][j];
                                    getTrackQueryData(groupArr[index][j]).then(res => {
                                        const b = (JSON.stringify(res.data.extra) === '{}');
                                        if (b === true) {
                                            res.data.extra['trackNo'] = orderNo;
                                        }
                                        return resolve(res);
                                    });
                                }));
                            }
                            index++;
                        }
                        const time = setInterval(() => {
                            if (index < groupArr.length) {
                                for (let j = 0; j < groupArr[index].length; j++) {
                                    let orderNo = groupArr[index][j];
                                    map.push(new Promise((resolve, reject) => {
                                        getTrackQueryData(groupArr[index][j]).then(res => {
                                            const b = (JSON.stringify(res.data.extra) === '{}');
                                            if (b === true) {
                                                res.data.extra['trackNo'] = orderNo;
                                            }
                                            return resolve(res);
                                        }).catch(() => {
                                            reject()
                                        });
                                    }));
                                }
                                index++;
                            } else {
                                Promise.all(map).then(data => {
                                    data.map(item => {
                                        if (item.status === 200) {
                                            if (item.data.code === 200) {
                                                const order = item.data.data;
                                                const orderDetail = order; // JSON.parse(order);
                                                orderDetail.orderNum = item.data.extra.trackNo;
                                                orderDetail.countryCode = item.data.extra.countryCode;
                                                orderDetail.orderList = [];
                                                // if (orderDetail.length > 1) {
                                                //     let t = 0;
                                                //     for (let j = 0; j < orderDetail.length; j++) {
                                                //         for (let k = 0; k < orderDetail.length; k++) {
                                                //             if (new Date(orderDetail[j].date).getTime() < new Date(orderDetail[k].date).getTime()) {
                                                //                 t = orderDetail[k];
                                                //                 orderDetail[k] = orderDetail[j];
                                                //                 orderDetail[j] = t;
                                                //             }
                                                //         }
                                                //     }
                                                // }
                                                orderDetail.map(item => {
                                                    orderDetail.orderList.push(item);
                                                });
                                              if (orderDetail[0].stateCode === 'leave_orgin_place'
                                                    || orderDetail[0].stateCode === 'forecast'
                                                    || orderDetail[0].stateCode === 'arrive_at_warehouse'
                                                    || orderDetail[0].stateCode === 'leave_warehouse') {
                                                    orderDetail.statusCode = 0
                                                } else if (orderDetail[0].stateCode === 'transport'
                                                    || orderDetail[0].stateCode === 'air_take_off'
                                                    || orderDetail[0].stateCode === 'arrived_at_airline') {
                                                    orderDetail.statusCode = 1
                                                } else if (
                                                    orderDetail[0].stateCode === 'customs_buckle'
                                                    || orderDetail[0].stateCode === 'tax_failure'
                                                    || orderDetail[0].stateCode === 'customs_paid'
                                                    || orderDetail[0].stateCode === 'tax_to_be_paid'
                                                    || orderDetail[0].stateCode === 'clearance_successful'
                                                    || orderDetail[0].stateCode === 'clearance_start'
                                                    || orderDetail[0].stateCode === 'clearance_failed'
                                                    || orderDetail[0].stateCode === 'customs_pay_tax'
                                                    || orderDetail[0].stateCode === 'customs_detention'
                                                    || orderDetail[0].stateCode === 'arrival_at_destination_airport'
                                                    || orderDetail[0].stateCode === 'parcel_damaged'
                                                    || orderDetail[0].stateCode === 'return_to_sender'
                                                    // || orderDetail[0].stateCode === 'delivering'
                                                    || orderDetail[0].stateCode === 'post_office_of_destination_country'
                                                    || orderDetail[0].stateCode === 'arrive_to_collect'
                                                    || orderDetail[0].stateCode === 'delivery_failed'
                                                    || orderDetail[0].stateCode === 'in_delivery'
                                                    || orderDetail[0].stateCode === 'try_to_deliver'
                                                    || orderDetail[0].stateCode === 'send_back'
                                                    || orderDetail[0].stateCode === 'abnormal'
                                                    || orderDetail[0].stateCode === 'lost'
                                                    || orderDetail[0].stateCode === 'reassign'
                                                    || orderDetail[0].stateCode === 'destroy'
                                                ) {
                                                    orderDetail.statusCode = 2
                                                } else if (
                                                    // orderDetail[0].stateCode === 'parcel_damaged'
                                                    // || orderDetail[0].stateCode === 'return_to_sender'
                                                    // // || orderDetail[0].stateCode === 'delivering'
                                                    // || orderDetail[0].stateCode === 'post_office_of_destination_country'
                                                    // || orderDetail[0].stateCode === 'arrive_to_collect'
                                                    // || orderDetail[0].stateCode === 'delivery_failed'
                                                    // || orderDetail[0].stateCode === 'in_delivery'
                                                    // || orderDetail[0].stateCode === 'try_to_deliver'
                                                    orderDetail[0].stateCode === 'delivered'
                                                    // || orderDetail[0].stateCode === 'send_back'
                                                    // || orderDetail[0].stateCode === 'abnormal'
                                                    // || orderDetail[0].stateCode === 'lost'
                                                    // || orderDetail[0].stateCode === 'reassign'
                                                    // || orderDetail[0].stateCode === 'destroy'
                                                ) {
                                                    orderDetail.statusCode = 3
                                                } else if (orderDetail[0].stateCode === 'delivering') {
                                                    const newArr = JSON.parse(JSON.stringify(orderDetail));
                                                    newArr.reverse();
                                                    for (let i = 0; i < newArr.length; i++) {
                                                        if (newArr[i].stateCode !== 'delivering') {
                                                            if (newArr[i].stateCode === 'leave_orgin_place'
                                                                || newArr[i].stateCode === 'forecast'
                                                                || newArr[i].stateCode === 'arrive_at_warehouse'
                                                                || newArr[i].stateCode === 'leave_warehouse') {
                                                                orderDetail.statusCode = 0
                                                            } else if (newArr[i].stateCode === 'transport'
                                                                || newArr[i].stateCode === 'air_take_off'
                                                                || newArr[i].stateCode === 'arrived_at_airline') {
                                                                orderDetail.statusCode = 1
                                                            } else if (
                                                                newArr[i].stateCode === 'customs_buckle'
                                                                || newArr[i].stateCode === 'tax_failure'
                                                                || newArr[i].stateCode === 'customs_paid'
                                                                || newArr[i].stateCode === 'tax_to_be_paid'
                                                                || newArr[i].stateCode === 'clearance_successful'
                                                                || newArr[i].stateCode === 'clearance_start'
                                                                || newArr[i].stateCode === 'clearance_failed'
                                                                || newArr[i].stateCode === 'customs_pay_tax'
                                                                || newArr[i].stateCode === 'customs_detention'
                                                                || newArr[i].stateCode === 'arrival_at_destination_airport'
                                                                || newArr[i].stateCode === 'parcel_damaged'
                                                                || newArr[i].stateCode === 'return_to_sender'
                                                                || newArr[i].stateCode === 'post_office_of_destination_country'
                                                                || newArr[i].stateCode === 'arrive_to_collect'
                                                                || newArr[i].stateCode === 'delivery_failed'
                                                                || newArr[i].stateCode === 'in_delivery'
                                                                || newArr[i].stateCode === 'try_to_deliver'
                                                                || newArr[i].stateCode === 'send_back'
                                                                || newArr[i].stateCode === 'abnormal'
                                                                || newArr[i].stateCode === 'lost'
                                                                || newArr[i].stateCode === 'reassign'
                                                                || newArr[i].stateCode === 'destroy'
                                                            ) {
                                                                orderDetail.statusCode = 2
                                                            } else if (
                                                                // newArr[i].stateCode === 'parcel_damaged'
                                                                // || newArr[i].stateCode === 'return_to_sender'
                                                                // || newArr[i].stateCode === 'post_office_of_destination_country'
                                                                // || newArr[i].stateCode === 'arrive_to_collect'
                                                                // || newArr[i].stateCode === 'delivery_failed'
                                                                // || newArr[i].stateCode === 'in_delivery'
                                                                // || newArr[i].stateCode === 'try_to_deliver'
                                                                newArr[i].stateCode === 'delivered'
                                                                // || newArr[i].stateCode === 'send_back'
                                                                // || newArr[i].stateCode === 'abnormal'
                                                                // || newArr[i].stateCode === 'lost'
                                                                // || newArr[i].stateCode === 'reassign'
                                                                // || newArr[i].stateCode === 'destroy'
                                                            ) {
                                                                orderDetail.statusCode = 3
                                                            }
                                                            break;
                                                        }
                                                    }
                                                }

                                                if (orderDetail[0].stateCode === 'arrive_to_collect') {
                                                    orderDetail.orderStatus = 1;
                                                    this.orderNumber.arriveCollect.push(orderDetail); // 到达待取
                                                    this.orderNumber.orderAll.push(orderDetail);
                                                } else if (orderDetail[0].stateCode === 'delivery_failed') {
                                                    orderDetail.orderStatus = 2;
                                                    this.orderNumber.deliveryFailed.push(orderDetail); // 投递失败
                                                    this.orderNumber.orderAll.push(orderDetail);
                                                } else if (orderDetail[0].stateCode === 'delivered') {
                                                    orderDetail.orderStatus = 3;
                                                    this.orderNumber.successfulSignIn.push(orderDetail); // 已签收
                                                    this.orderNumber.orderAll.push(orderDetail);
                                                } else if (orderDetail[0].stateCode === 'may_be_abnormal') {
                                                    orderDetail.orderStatus = 4;
                                                    this.orderNumber.itMayBeAbnormal.push(orderDetail); // 可能异常
                                                    this.orderNumber.orderAll.push(orderDetail);
                                                } else {
                                                    orderDetail.orderStatus = 5;
                                                    this.orderNumber.inTransit.push(orderDetail); // 运输中
                                                    this.orderNumber.orderAll.push(orderDetail);
                                                }
                                            } else {
                                                const orderItem = {
                                                    orderNum: item.data.extra.trackNo,
                                                    orderStatus: 0
                                                };
                                                this.orderNumber.orderAll.push(orderItem);
                                                this.orderNumber.notFound.push(orderItem);
                                            }
                                        }
                                    });
                                    this.isClick = true;
                                });
                                clearInterval(time);
                            }
                        }, 1100);
                    };
                    getArr(groupArr);
                } else {
                    console.log('查询中')
                }
            },
//            前往其他页面
            toNewWindow(type) {
                if (type === 1) {
                    window.open('http://www.szanjun.com');
                } else {
                    window.open('http://aj.hushengkj.com/reg.asp');
                }
            },
//            切换语言
          changeLanguage(type) {
            this.$i18n.locale = type;
            this.anJunImg = type === 'zh-CN' ? anjun : (type === 'en-US' ? logo : logoPu);
            this.getDivWidth();
          }
        },
        mounted() {
          // 页面大小该变的时候（缩放页面）设置table的高度（可加可不加）
            window.onresize = () => {
              if (this.isModule === false) {
                this.getDivWidth();
              }
            };
        }
    }

</script>
<style>
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .headerBtn {
        padding: 10px 30px;
        background: #009B4C;
        border-radius: 20px;
        color: #fff;
        font-size: 12px;
    }

    textarea {
        resize: none;
        border-color: #ccc;
        outline: none;
        padding: 10px;
        border-radius: 5px;
    }
    .container-out {
        border-bottom: 1px solid #ccc;
        height: 36px;
    }
    .left-con {
        margin-right: 5px;
        float: left;
        width: 20px;
        height: 36px;
        line-height: 36px;
        cursor: pointer;
        text-align: center;
        background:rgba(0,0,0,0.1);
    }
    .right-con {
        margin-left: 5px;
        float: right;
        width: 20px;
        height: 36px;
        cursor: pointer;
        text-align: center;
        line-height: 36px;
        background:rgba(0,0,0,0.1);
    }
    .right-con:hover, .left-con:hover {
      background:rgba(0,0,0,0.2);
    }
    .qq-con {
      font-size: 14px;
      font-weight: bold;
      color:#056F34;
      display: inline-block;
      float: right;
      width: 315px;
      line-height: 36px
    }
    .item-con {
      position:absolute;
      transition-property: left;
      transition-duration: 0.3s;
    }
    .loopCon {
      display: inline-block;
      width: calc(100% - 60px);
      overflow: hidden;
      height: 36px;
      position:relative;
    }
    ul {
        display: inline-block;
        list-style: none;
        height: 36px;
    }

    ul > li {
        display: inline-block;
        height: 36px;
        padding: 6px 10px;
        font-size: 14px;
        font-weight: bold;
        border-bottom: 2px solid transparent;
    }

    li.borderBtm {
        border-bottom: 2px solid #008000;
    }

    .transformJt {
        transform: rotate(-45deg) !important;
        margin-top: -8px !important;
    }
    .selectLg {
      position: relative;
      display: inline-block;
    }
    .selectCon {
      background: #fff;
      display: none;
      position: absolute;
      top: 20px;
      right: 0;
      width: 120px;
      text-align: left;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    .lgCon {
      border: 1px solid #009B4C;
      border-radius: 3px;
      padding: 3px 5px;
      color: #009B4C;
      font-size: 12px;
      margin-right: 10px;
      cursor: pointer;
    }
    .selectLg:hover .selectCon {
      display: block;
    }
</style>
